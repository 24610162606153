import React, {useState, useEffect} from 'react'
import './Education.scss'

import { motion } from 'framer-motion'
import {AppWrap} from '../../wrapper'
import {client} from '../../client'
import { images } from '../../constants'

const Education = () => {

    const [edu, setEdu] = useState([])

    useEffect(() => {
        const query ='*[_type == "education"]'
        client.fetch(query)
            .then((data) => { 
                setEdu(data)
            })
    }, [])

    return (
        <>  
            <h2 className="head-text" style={{paddingTop: 20, marginBottom: 30}}>
                    <span> Education </span>
            </h2>
            <motion.div 
                whileInView={{x: [-100, 0], opacity: [0,1]}}
                transition={{duration: 0.5, delay:0.25}}
                className="app__education-list"
            >
                <div className="timeline-container">
                    {edu.map((item, index) => {
                        return (
                            <div className="app__education-item app__flex timeline-item" key={index}>
                                <div className="app__education-content  timeline-item-content app__flex">
                                    <h4 className="bold-text"> {item.name} </h4>
                                    <p className="p-text" style={{marginTop: 10}}><span> 🎓</span> {item.school} </p>
                                    <p className="p-text" style={{marginTop: 10}}><span> ℹ️ </span> {item.desc} </p>
                                    <p className="p-text" style={{marginTop: 10}}>
                                    <span> 🗓️ </span> From <span style={{color: "#313bac"}}> {item.startDate} </span> to <span style={{color: "#313bac"}}> {item.endDate} </span>
                                    </p>
                                    <p className="p-text" style={{marginTop: 10}}>
                                        <span>📍</span> {item.location}
                                    </p>
                                    <span className="circle" />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </motion.div>
        </>
    )
}

export default AppWrap(Education, 'education')