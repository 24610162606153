import React, {useState, useEffect} from 'react'
import './Experience.scss'

import { AppWrap } from '../../wrapper'
import { motion } from 'framer-motion'
import { client } from '../../client'

const Experience = () => {

    const [experiences, setExperiences] = useState([])

    useEffect(() => {
        const query ='*[_type == "workExperience"] | order(_createdAt asc)'
        client.fetch(query)
            .then((data) => { 
                setExperiences(data)
            })
    }, [])

    return (
        <>  
            <h2 className="head-text" style={{paddingTop: 20, marginBottom: 30, color: '#313BAC', top: 30}}>
                    <span> Professionnal Experience </span>
            </h2>
            <div className="app__experience-content">
                {experiences.map((item, index) => {
                    return (
                        <motion.div
                            whileInView={{x: [-100, 0], opacity: [0,1]}}
                            transition={{duration: 0.5, delay: index*0.25, staggerChildren: 0.25}}
                        >
                            <div className="app__experience-item app__flex" key={item.title + index}>
                                    <div style={{display:"flex", flexDirection:'row', alignItems:"center", marginTop: 15}}>
                                        <span style={{marginRight: 15}}> ➢ </span> 
                                        <h4 className="bold-text" style={{color: "#313bac"}}> {item.name} </h4>
                                    </div>
                                    <div style={{marginLeft: 30, flexDirection: "column", display:'flex'}}>
                                        <br />
                                        <span className="p-text"> 🏢 {item.company} </span>
                                        <span className="p-text"> 🛠️ {item.desc} </span>
                                        <br />
                                        {item.skills ?
                                            <>
                                                <span className="p-text"> Technologies : </span>
                                                {item.skills.map((skill) => {
                                                    return (
                                                        <p className="p-text" style={{marginLeft: 20}}>
                                                            <span> 💡 </span> <span> {skill}</span>
                                                        </p>
                                                    )
                                                })}
                                            </> : <></>
                                        }
                                    </div>
                                    <div style={{marginLeft: 30}}>
                                        <p className="p-text" style={{marginTop: 10}}>
                                                <span>📍</span> {item.location}
                                        </p>
                                        <p className="p-text">
                                            <span> 🗓️ </span> From <span style={{color: "#313bac"}}> {item.startDate} </span> to <span style={{color: "#313bac"}}> {item.endDate} </span>
                                        </p>    
                                    </div>
                                    
                            </div> 
                        </motion.div>
                    )
                })}
            </div>
        </>
    )
}

export default AppWrap(Experience, 'experience')
