import React, {useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import {AppWrap , MotionWrap} from '../../wrapper'
import {urlFor, client} from '../../client'
import './Skills.scss'

const Skills = () => {

    const [skills, setSkills] = useState([])

    useEffect(() => {
        const query ='*[_type == "skills"]'
        client.fetch(query)
            .then((data) => { 
                setSkills(data.filter((skill) => !skill._id.includes('drafts')))
                console.log(data)
            })
    }, [])

    return (
        <>
            <h2 className="head-text" style={{paddingTop: 20, color: '#313BAC', top: 30}}>
                    <span> Skills </span>
            </h2>
            <div className="app__skills-container">
                <motion.div
                    className="app__skills-list"
                >   
                    <p className="head-text" style={{color: '#6b7688', fontWeight: 500, maxHeight: 60}}> Programming </p>
                    <div style={{display:"flex", flexDirection: "row", flexWrap: 'wrap', justifyItems: 'flex-start'}} className="app__flex">
                        {skills.filter((skill) => skill.type === "programming").map((skill) => {
                            return (
                                <motion.div
                                    whileInView={{opacity: [0, 1]}}
                                    transition={{duration: 0.5}}
                                    className="app__skills-item app__flex"
                                    key={skill.name}
                                >
                                    <div className="app__flex" style={{backgroundColor: skill.bgColor}}>
                                        <img src={urlFor(skill.icon)} alt="skill_name"/>
                                    </div>
                                    <p className="p-text" style={{textAlign: 'center', color: "#312BAC"}}>
                                        {skill.name}
                                    </p>
                                    <p className="p-text" style={{textAlign: 'center'}}>
                                        {skill.level}
                                    </p>
                                </motion.div> 
                            )
                        })}
                    </div>
                </motion.div>
                <motion.div
                    className="app__skills-list"
                >   
                    <p className="head-text" style={{color: '#6b7688', fontWeight: 500, maxHeight: 60}}> Computer </p>
                    <div style={{display:"flex", flexDirection: "row", flexWrap: 'wrap', justifyItems: 'flex-start'}} className="app__flex">
                        {skills.filter((skill) => skill.type === "computer").map((skill) => {
                            return (
                                <motion.div
                                    whileInView={{opacity: [0, 1]}}
                                    transition={{duration: 0.5}}
                                    className="app__skills-item app__flex"
                                    key={skill.name}
                                >
                                    <div className="app__flex" style={{backgroundColor: skill.bgColor}}>
                                        <img src={urlFor(skill.icon)} alt="skill_name"/>
                                    </div>
                                    <p className="p-text" style={{textAlign: 'center', color: "#312BAC"}}>
                                        {skill.name}
                                    </p>
                                    <p className="p-text" style={{textAlign: 'center'}}>
                                        {skill.level}
                                    </p>
                                </motion.div> 
                            )
                        })}
                    </div>
                </motion.div>
                <motion.div
                    className="app__skills-list"
                >   
                    <p className="head-text" style={{color: '#6b7688', fontWeight: 500, maxHeight: 60}}> Language </p>
                    <div style={{display:"flex", flexDirection: "row", flexWrap: 'wrap', justifyItems: 'flex-start'}} className="app__flex">
                        {skills.filter((skill) => skill.type === "language").map((skill) => {
                            return (
                                <motion.div
                                    whileInView={{opacity: [0, 1]}}
                                    transition={{duration: 0.5}}
                                    className="app__skills-item app__flex"
                                    key={skill.name}
                                >
                                    <div className="app__flex" style={{backgroundColor: skill.bgColor}}>
                                        <img src={urlFor(skill.icon)} alt="skill_name"/>
                                    </div>
                                    <p className="p-text" style={{textAlign: 'center', color: "#312BAC"}}>
                                        {skill.name}
                                    </p>
                                    <p className="p-text" style={{textAlign: 'center'}}>
                                        {skill.level}
                                    </p>
                                </motion.div> 
                            )
                        })}
                    </div>
                </motion.div>
            </div>
        </>
    )
} 
export default AppWrap( MotionWrap(Skills, 'app__skills'), 'skills', "app__primarybg")
