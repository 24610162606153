import React, {useState} from 'react'
import './Footer.scss'
import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'
import { client } from '../../client'

const Footer = () => {

    const [formData, setFormData] = useState({name: '', email: '', message: ''})
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const {name, email, message} =  formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target
        setFormData({...formData, [name] : value})
    }

    const handleSubmit = () => {

        setLoading(true);
        const contact = {
            _type: 'contact',
            name: name,
            email: email,
            message: message
        }

        client.create(contact)
            .then(()=>{
                setLoading(false);
                setIsFormSubmitted(true)
            })
    }

    const handleNewMessage = () => {
        setLoading(false);
        setIsFormSubmitted(false);
    }

    return (
        <>
            <h2 className="head-text">
                How to <span> contact </span> me
            </h2>
            <div className="app__footer-cards">
                <div className="app__footer-card">
                    <img src={images.email} alt="mail"/>
                    <a href="mailto: lerner.thomas1911@gmail.com" className="p-text"> lerner.thomas1911@gmail.com </a>
                </div>
                <div className="app__footer-card">
                    <img src={images.mobile} alt="mail"/>
                    <a href="tel: +33 (6 12 15 87 12)" className="p-text"> +33 6 12 15 87 12 </a>
                </div>
            </div>

            {!isFormSubmitted ?
                <div className="app__footer-form app__flex">
                    <p style={{color: '#6b7688', marginBottom: 30}}> or </p>

                    <div className="app__flex">
                        <input className="p-text" type ="text" placeholder="Your name" value={name} onChange={handleChangeInput} name="name"/>
                    </div>
                    <div className="app__flex">
                        <input className="p-text" type ="email" placeholder="Your email" value={email} onChange={handleChangeInput} name="email"/>
                    </div>
                    <div>
                        <textarea 
                            className="p-text"
                            placeholder="Your message"
                            value={message}
                            name="message"
                            onChange={handleChangeInput}
                        />
                    </div>
                    <button type="button" className="p-text" onClick={handleSubmit}> {loading ? "Sending... " : "Send Message"} </button>
                </div> 
            :
                <div className="app__footer-form app__flex">
                    <h3 className="head-text">
                        <span> Thank you! </span> I will get back to you <span> soon</span>! 
                    </h3>
                    <button type="button" className="p-text" onClick={handleNewMessage}> Send another message </button>
                </div>
            }
        </>
    )
}

export default AppWrap(MotionWrap(Footer, 'app__footer'), 'contact', 'app__whitebg')
