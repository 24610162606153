import React, {useState, useEffect} from 'react';
import './About.scss';
import { urlFor, client } from '../../client';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper'

import { motion } from 'framer-motion';


const About = () => {

    const [abouts, setAbouts] = useState([]);
    useEffect(()=> {
        const query = '*[_type == "abouts"] | order(_createdAt asc)';

        client.fetch(query)
            .then((data) => setAbouts(data))

    }, []);

    return (
        <div>
            <div className="app__intro">
                <h2 className="head-text" style={{paddingTop: 20, paddingBottom: 30}}>
                    I am looking for a <span> final year internship</span>.
                </h2>
                <div className="app__description-container">
                    <img src={images.informations} alt='information'/>
                    <p className="app__description">
                        Currently in my final year at Université de Technology de Compiègne (France), 
                        I am seeking a final year internship and I wish to be working abroad. I am dynamic and I fit well in teams. 
                        I am eager to learn and to discover new technologies.
                    </p>
                </div>
            </div>
            <div className="app__profiles">
                {abouts.map((about, index) => {
                    return (
                        <motion.div
                            whileInView={{opacity: 1}}
                            whileHover={{scale: 1.1}}
                            transition={{duration: 0.5, type: 'tween', }}
                            className="app__profile-item"
                            key={about.title + index}
                        >
                            <img src={urlFor(about.imgUrl)} alt={about.title}/>
                            <h2 className="bold-text" style={{marginTop: 20,  color: "#313bac"}}>{about.title}</h2>
                            <p className="bold-text" style={{marginTop: 10}}>{about.description}</p>
                        </motion.div>
                    )
                })}
            </div>
        </div>
    )
}

export default AppWrap( MotionWrap(About, 'app__about'), 'about', "app__whitebg")
